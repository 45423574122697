.info-block-container{
  padding-bottom: 48px;

  @include sm{
    padding: 0 16px 40px 16px
  }

  &.full-width{
    padding-bottom: 0;
    @include sm{
      padding: 40px 16px 40px 16px
    }
  }

  
}
.info-block {
  @extend %row;

  border: 1px solid $light;
  padding: 20px;
  padding-right: 40px;

  @include rtl {
    padding-right: 20px;
    padding-left: 40px;
  }

  @include lg {
    padding-right: 24px;


    @include rtl {
      padding-right: 20px;
      padding-left: 24px;
    }
  }

  @include md {

    padding-right: 20px;

    @include rtl {
      padding-left: 20px;
    }
  }

  @include sm {
    flex-direction: column-reverse;
    gap: 20px;

    padding: 10px;
    margin-bottom: 0;
  }

  &__img {
    align-self: flex-start;

    position: relative;
    background-color: $light;

    width: percentage(math.div(364, 1124));
    padding-top: percentage(math.div(264, 364)) * (math.div(364, 1124));

    @include lg {
      width: percentage(math.div(272, 828));
      padding-top: percentage(math.div(198, 272)) * (math.div(272, 828));
    }

    @include md {
      width: percentage(math.div(232, 616));
      padding-top: percentage(math.div(169, 232)) * (math.div(232, 616));
    }

    @include sm {
      width: 100%;
      order: 1;
      padding-top: percentage(math.div(242, 335));
    }

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }
  }

  &__content {
    width: percentage(math.div(1124 - 364, 1124));
    padding-left: 40px;
    padding-top: 10px;

    strong{
      color: $accent-blue;
    }

    @include rtl {
      padding-left: 0;
      padding-right: 40px;
    }

    @include lg {
      width: percentage(math.div(828 - 272, 828));
      padding-left: 24px;
      padding-top: 0;

      @include rtl {
        padding-left: 0;
        padding-right: 24px;
      }
    }

    @include md {
      width: percentage(math.div(616 - 232, 616));
      padding-left: 20px;

      @include rtl {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    @include sm {
      width: 100%;
      padding-left: 0;
      margin-bottom: 0;

      @include rtl {
        padding-right: 0;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    @include text-gradient;
    width: fit-content;
  }
}
