$base-fz: 15;
$base-desktop: 1440;
$base-mobile: 375;

$text-inputs: "[type='color']", "[type='date']", "[type='datetime']", "[type='datetime-local']",
  "[type='email']", "[type='month']", "[type='number']", "[type='password']", "[type='search']",
  "[type='tel']", "[type='text']", "[type='time']", "[type='url']", "[type='week']",
  'input:not([type])', 'textarea';
$check-inputs: "[type='checkbox']", "[type='radio']";

$c-text: #000;

$gradient: linear-gradient(101.96deg, #004A72 4.2%, #004A72 36.26%, #E53212 73.82%, #E53212 95.8%);

$accent-blue: #004A73;
$accent-red: #E63312;

$black: #000;
$white: #fff;
$accent: #004A73;
$accent-hover: #00872f;
$light: #CEDCE2;
$lighten: #f6f6f6;
$danger: #e3000b;
$light-grey:#F5F8F9;

$header-height: 144;
$header-height-xxxl: 108;
$header-height-lg: 108;
$header-height-sm: 100;


