// width
@mixin r($width) {
  @media (max-width: $width + 'px') {
    @content;
  }
}

@mixin rmin($width) {
 
    @content;
 
}

// height
@mixin rh($height) {
  @media (max-height: $height + 'px') {
    @content;
  }
}

@mixin rhmin($height) {
  @media (min-height: $height + 'px') {
    @content;
  }
}

@mixin xxxl {
  @include r(1919) {
    @content;
  }
}

@mixin xxl {
  @include r(1439) {
    @content;
  }
}

@mixin xl {
  @include r(1399) {
    @content;
  }
}

@mixin lg {
  @include r(1199) {
    @content;
  }
}

@mixin md {
  @include r(1023) {
    @content;
  }
}

@mixin sm {
  @include r(767) {
    @content;
  }
}

@mixin xs {
  @include r(575) {
    @content;
  }
}

@mixin xxs {
  @include r(479) {
    @content;
  }
}

@mixin xxxs {
  @include r(374) {
    @content;
  }
}

@mixin xxxlmin {
  @include rmin(1920) {
    @content;
  }
}

@mixin xxlmin {
  @include rmin(1440) {
    @content;
  }
}

@mixin xlmin {
  @include rmin(1400) {
    @content;
  }
}

@mixin lgmin {
  @include rmin(1200) {
    @content;
  }
}

@mixin mdmin {
  @include rmin(1024) {
    @content;
  }
}

@mixin smmin {
  @include rmin(768) {
    @content;
  }
}

@mixin xsmin {
  @include rmin(576) {
    @content;
  }
}

@mixin xxsmin {
  @include rmin(480) {
    @content;
  }
}

@mixin xxxsmin {
  @include rmin(375) {
    @content;
  }
}
