.products {
  background-color: $lighten;

  padding-top: 60px;
  padding-bottom: 60px;

  @extend %row;

  @include xxxl {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @include sm {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &__nav {
    width: percentage(math.div(464, 1232));

    @include md {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__body {
    width: percentage(math.div(1232 - 464, 1232));
    padding-left: 40px;

    @include rtl {
      padding-left: 0;
      padding-right: 40px;
    }

    @include md {
      width: 100%;
      padding-left: 0;

      @include rtl {
        padding-right: 0;
      }
    }
  }

  &__top {
    margin-bottom: 40px;

    @include xxxl {
      margin-bottom: 32px;
    }

    .title--h3 {
      margin-bottom: 32px;

      @include xxxl {
        margin-bottom: 16px;
      }
    }

    > p {
      &:not(:last-child) {
        margin-bottom: 12px;

        @include xxxl {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__filters {
    margin-bottom: 24px;

    @include sm {
      margin-bottom: 16px;
    }

    .filter-block {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &__results {
    margin-bottom: 80px;

    @include xxxl {
      margin-bottom: 69px;
    }

    @include sm {
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid rgba($black, 0.1);
    }

    .title--h3 {
      margin-bottom: 16px;
    }
  }

  &__info-items {
    margin-bottom: 60px;

    @include xxxl {
      margin-bottom: 47px;
    }

    @include sm {
      margin-bottom: 32px;
    }
  }

  &__btn {
    @include md {
      text-align: center;
    }
  }

  &__overlay {
    @extend %overlay;
    z-index: 11;

    background-color: rgba(0, 0, 0, 0.15);

    @extend %hidden;

    transition: opacity 300ms, visibility 300ms;

    html:not(.no-touch) & {
      cursor: pointer;
    }

    &[data-state='active'] {
      @extend %visible;
    }
  }
}

.products-nav {
  position: relative;
  z-index: 12;

  @include md {
    text-align: center;
  }

  ul {
    position: sticky;
    top: 0;

    @include md {
      position: absolute;
      z-index: 5;
      top: 100%;
      left: 0;
      right: 0;

      background-color: $white;
      padding: 24px 24px 24px;

      @include hidden;

      &[data-state='open'] {
        @include visible;
      }
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: 16px;

      @include xxxl {
        margin-bottom: 12px;
      }

      @include md {
        margin-bottom: 16px;
      }
    }
  }

  button,
  a {
    display: block;
    width: 100%;
    text-align: inherit;

    position: relative;

    text-transform: uppercase;
    font-size: 16px;
    background-color: $white;

    padding: 20px 20px 20px 64px;

    overflow: hidden;

    transition: color 300ms;

    @include hover {
      color: $accent;
      font-weight: 700;
    }

    @include rtl {
      padding: 20px 64px 20px 20px;
    }

    @include xxxl {
      padding: 15px 15px 15px 48px;

      @include rtl {
        padding: 15px 48px 15px 15px;
      }
    }

    @include md {
      text-align: center;
      background-color: transparent;
      padding: 0;
      font-size: 14px;
    }

    &::before {
      content: '';

      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      width: 64px;

      clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);

      background-color: $accent;

      transform: translate(-100%, 0);

      @include rtl {
        left: auto;
        right: 0;

        transform: translate(100%, 0);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 40% 100%);
      }

      @include xxxl {
        width: 48px;
      }
    }

    &.is-active {
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.08);
      color: $accent;
      font-weight: 700;

      @include md {
        box-shadow: none;
        color: #b7b7b7;
      }

      &::before {
        transform: translate(0, 0);

        @include md {
          display: none;
        }
      }
    }
  }

  .title--h4 {
    display: none;
    margin-bottom: 16px;
    font-style: normal;
    font-size: 18px;
    font-weight: 700;

    @include md {
      display: block;
    }
  }

  &__title {
    display: none !important;
    font-weight: 700;

    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.08);
    color: $accent;

    padding: 12px 30px !important;
    background-color: $white !important;

    min-height: 60px;
    
    &::before {
      width: 33px !important;
      clip-path: polygon(0 0, 100% 0, 3px 100%, 0% 100%) !important;
      transform: translate(0, 0) !important;

      @include rtl {
        left: auto;
        right: 0;

        transform: translate(100%, 0);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 40% 100%);
      }


    }

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      right: 16px;

      @include icon-arrow(8, 8, 2, currentColor, -45, px);

      transform: translate(0, -50%) rotate(-45deg);

      @include rtl {
        right: auto;
        left: 16px;
      }
    }

    @include md {
      display: block !important;
    }

    &[data-state='active'] {
      &::after {
        transform: translate(0, -50%) rotate(-225deg);
      }
    }
  }
}

.products-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;

      @include md {
        margin-bottom: 16px;
      }

      @include sm {
        margin-bottom: 12px;
      }
    }
  }
}

.product-card {
  $this: &;

  display: flex;
  flex-direction: column;

  background-color: $white;

  &__benefits{
    margin-bottom: 30px;
    font-family: IBM Plex Sans;
  }

  &__in {
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 32px 32px 66px;

    flex-grow: 1;

    @include xxxl {
      padding: 24px 15px 52px;
    }

    @include md {
      padding: 16px 16px 24px;
    }
  }
  .filter-block{
    &__list{
      margin: 16px 0 8px; 
    }
    &__btn{
      span{
        border: 1px solid #BEBEBE;
      }
      input:checked+span{
        background-color: transparent;
        border-color: #00A138;
        color: #000000;
      }
    }
  }
  &__title-block,
  &__content {
    padding-left: percentage(math.div(224, 728));

    @include rtl {
      padding-left: 0;
      padding-right: percentage(math.div(224, 728));
    }

    @include sm {
      padding-left: 0;

      @include rtl {
        padding-right: 0;
      }
    }
  }

  &__title-block {
    margin-bottom: 24px;

    @include xxxl {
      margin-bottom: 16px;
    }

    @include md {
      margin-bottom: 12px;
    }

    > p {
      display: inline-flex;
      font-weight: 700;
      b{
        padding-right: 8px;
      }
    }
    .volume{
      display: none;
    }
  }
  &__intervals{
    margin-bottom: 5px !important;
  }
  &__recomendation{
    font-family: IBM Plex Sans;
    font-size: 12px;
    color: #00000099;

  }
  &__title {
    display: block;
    margin-bottom: 4px;
    font-style: normal;

    @include md {
      font-size: 18px;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: percentage(math.div(200, 728));

    @include rtl {
      left: auto;
      right: 0;
    }

    @include sm {
      position: relative;
      width: 100%;

      padding-top: 69%;
    }

    img {
      max-height: 90%;
      margin-top: 30px;

      @include sm {
        @include vcenter;
        margin-top: 0;
      }
    }
  }

  &__content {
    ul{
      margin-bottom: 12px;
    }
    .text-small{
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color: rgba(0, 0, 0, 0.6);
      margin-top: -10px;
    }
  }

  &__btns {
    display: flex;
    position: relative;

    .btn {
      // width: percentage(math.div(374, 728));
      width: 196px;
      background-color: $accent-red;
      margin-left: percentage(math.div(224, 728));
      margin-bottom: 22px;
      font-size: 16px;

      @include sm {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 14px;

      }

      // &:last-child {
      //   margin-left: auto;

      //   @include rtl {
      //     margin-left: 0;
      //     margin-right: auto;
      //   }
      // }
    }
  }
&--short{
  .filter-block__list{
    display: none;
  }
  .product-card__benefits{
    margin-bottom: 0;
  }
  .product-card__content{
    >p{
      display: none;
    }
  }
  .volume{
    display: block;
    font-weight: 400;
    line-height: 120%;
    margin: 8px 0 4px;
  }
}
  &--vert {
    #{$this}__img {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      margin-bottom: 10px;
      width: 100%;
      padding-top: 61%;

      img {
        @include vcenter;
        margin-top: 0;
      }
    }

    #{$this}__title-block,
    #{$this}__content {
      padding-left: 0;

      @include rtl {
        padding-right: 0;
      }
    }

    #{$this}__in {
      padding: 32px;

      @include xxxl {
        padding: 24px 24px 28px;
      }

      @include sm {
        padding: 16px 16px 24px;
      }
    }

    #{$this}__title-block {
      margin-bottom: 0;
      height: 5.7em;
    }

    #{$this}__btns {
      @include smmin {

        .btn {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.products-info-items-list {
  @extend %row;

  margin-left: em(-7.5);
  margin-right: em(-7.5);

  &__item {
    width: 50%;

    padding-left: em(7.5);
    padding-right: em(7.5);

    @include sm {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    .tooltip__wrap {
      margin-bottom: 24px;

      @include sm {
        margin-bottom: 16px;
      }
    }

    .product-card {
      height: 100%;

      @include sm {
        height: auto;
      }
    }
  }

  &--desk {
    @include sm {
      display: none;
    }
  }
}
