.countries-modal {
  @extend %overlay;
  z-index: 15;

  background-color: $white;
  color: $black;

  padding: 40px 64px;

  overflow: auto;

  transition: opacity 300ms, visibility 300ms;

  @extend %hidden;

  @include lg {
    padding: 40px;
  }

  @include sm {
    padding: 12px 20px 20px;
  }

  &__close {
    position: absolute;
    top: 64px;
    right: 64px;

    @include rtl {
      right: auto;
      left: 64px;
    }

    @include lg {
      right: 40px;

      @include rtl {
        right: auto;
        left: 40px;
      }
    }

    @include sm {
      right: 20px;
      top: 32px;

      @include rtl {
        right: auto;
        left: 20px;
      }
    }

    .icon {
      font-size: 16px;
    }
  }

  &__inner {
    margin-top: 24px;

    @include sm {
      margin-top: 48px;
    }
  }

  &__title {
    margin-bottom: 64px;
    text-align: center;

    @include sm {
      text-align: inherit;
      margin-bottom: 32px;
    }
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: 80px - 16px;

      @include sm {
        margin-bottom: 28px;
      }
    }
  }

  &__block-title {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 4px;

    .icon {
      fill: none;
      fill-rule: evenodd;
      clip-rule: evenodd;
      color: $accent;

      path {
        fill: currentColor;
      }
    }
  }

  &__list {
    @extend %row;
  }

  &__list-item {
    width: percentage(math.div(1, 4));

    padding-right: 16px;
    margin-bottom: 16px;

    @include rtl {
      padding-right: 0;
      padding-left: 16px;
    }

    @include sm {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  &__list-link {
    transition: color 300ms;

    @include hover {
      color: $accent;
    }
  }

  &[data-state='open'] {
    @extend %visible;
  }
}
