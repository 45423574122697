.title {
  font-weight: 700;

  &--h1 {
    font-size: 52px;

    font-style: italic;
    font-weight: 700;

    @include xxxl {
      font-size: 40px;
    }

    @include xxl {
      font-size: 40px;
      line-height: 1.25;
    }

    @include md {
      font-size: 28px;
      line-height: 1.14;
    }
  }

  &--h2 {
    font-size: 32px;
    font-style: italic;
    font-weight: 700;

    @include xxxl {
      font-size: 26px;
    }

    @include xxl {
      font-size: 26px;
    }
    @include md{
      font-size: 21px;
    }
  }

  &--h3 {
    font-size: 32px;

    @include xxxl {
      font-size: 24px;
    }



  }

  &--h4 {
    font-size: 28px;
    font-style: italic;
    font-weight: 700;

    @include xxxl {
      font-size: 20px;
    }

    @include xxl {
      letter-spacing: 0.04em;
    }

    @include md {
      font-size: 18px;
      letter-spacing: initial;
    }
  }

  &--h5 {
    font-size: 1rem;
  }
}

.text {
  a {
    color: $accent;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  ul {
    list-style-type: disc;
    margin-left: 1em;
  }

  ol {
    list-style-type: decimal;
    margin-left: 1em;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

p > a {
  color: $accent;
}

.accent {
  color: $accent;
}

.light {
  color: rgba(0, 0, 0, 0.6);
}
