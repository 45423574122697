.tabs {
  $this: &;



  &__tabs {
    margin-bottom: -2px;

    @include sm {
      overflow: auto;
      padding-left: 15px;
    }

    ol {
      counter-reset: list-tabs;

      display: flex;
      align-items: center;

      max-width: 336 * 5 + px;
      min-width: 100%;

      @include xxxl {
        max-width: 252 * 5 + px;
      }

      @include sm {
        width: 704px;
        min-width: 100%;
      }

      @include sm {
        width: 551px;
      }
    }
  }

  &__back {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    position: absolute;
    top: 20px;
    left: 48px;

    color: $accent;
    letter-spacing: 0.04em;

    transition: color 300ms;

    @include hover {
      color: $black;
    }

    @include rtl {
      left: auto;
      right: 48px;
    }

    @include lg {
      position: static;
      margin-bottom: 16px;
    }
    @include sm {
      margin-bottom: 0;
      margin-left: 16px;
    }

    .icon {
      transform: scaleX(-1);
      font-size: 13px;

      @include rtl {
        transform: none;
      }

      @include xxxl {
        font-size: 10px;
      }
    }
  }

  &__body {
    position: relative;

    background-color: $white;
    color: $black;

    padding: 64px 60px 60px;

    @include xxxl {
      padding: 50px 48px 0 48px;
    }

    @include lg {
      padding: 34px 36px 0 36px;
    }

    @include md {
      padding: 24px 24px 0 24px;
    }

    @include sm {
      padding:0;
      padding-top: 20px;
    }

    .search-block {
      margin-bottom: 40px;

      @include lg {
        margin-bottom: 32px;
      }
      @include sm {
        margin-bottom: 0;
      }
    }

    .vehicle-cards {
      margin-bottom: 48px;

      @include lg {
        margin-bottom: 40px;
      }

      @include sm {
        margin-bottom: 32px;
      }
    }


    .brands,
    .info-list-items,
    .products {
      margin-left: -60px;
      margin-right: -60px;

      padding-left: 60px;
      padding-right: 60px;

      @include xxxl {
        margin-left: -48px;
        margin-right: -48px;

        padding-left: 48px;
        padding-right: 48px;
      }

      @include lg {
        margin-left: -36px;
        margin-right: -36px;

        padding-left: 36px;
        padding-right: 36px;
      }

      @include md {
        margin-left: -24px;
        margin-right: -24px;

        padding-left: 24px;
        padding-right: 24px;
      }

      @include sm {
        margin-left: -12px;
        margin-right: -12px;

        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .brands{
      padding-top: 48px;
      padding-bottom: 48px;

      @include sm{
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 16px;
        padding-left: 16px;
        margin: 0;
      }
    }

    .info-list-items{
      @include sm{
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 16px;
        padding-left: 16px;
        margin: 0;
      }
    }

    .products {
      padding-left: 0;

      @include rtl {
        padding-right: 0;
        padding-left: 36px;
      }

      @include md {
        padding-left: 24px;

        @include rtl {
          padding-left: 24px;
          padding-right: 24px;
        }
      }

      @include sm {
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 0;
        margin-right: 0;

        @include rtl {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }



    .links-block{

      padding-bottom: 30px;

      @include sm{
        padding-right: 16px;
        padding-left: 16px;
      }

      &.full-width{
        >div{
          padding-top: 48px;
          padding-bottom: 0;
          @include sm{
            padding-top: 40px;
          }
        }
      }
    }

    .title-block {
      margin-bottom: 48px;

      @include xxxl {
        margin-bottom: 32px;
      }
      @include sm{
        padding-right: 16px;
        padding-left: 16px;
      }
    }
  }

  &__btn-item {
    position: relative;

    width: percentage(math.div(1, 5));
    color: $white;
    width: auto;
    flex-grow: 1;

    margin-left: -22px;
     &:nth-child(1){
      margin-left: 0;
     }

    @include md {
      width: auto;
      flex-grow: 1;
    }

    &--active {
      color: $black;

      #{$this}__btn {
        background-color: $white;
      }
    }

    &--active ~ #{$this}__btn-item {
      pointer-events: none;
    }
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    
    width: 100%;

    padding: 18px 45px;

    background-color: $accent-red;

    font-size: 22px;
    font-weight: 700;
    line-height: math.div(24, 16);

    transition: color 300ms;


    @include hover {
      color: $black;
    }

    @include mdmin {
      clip-path: polygon(26px 0, 100% 0, calc(100% - 26px) 100%, 0% 100%);

      @include rtl {
        clip-path: polygon(26px 0, 100% 0, 100% 100%, 0% 100%);
      }
    }


    @include xxxl {
      font-size: 16px;
      padding: 11px 45px 11px 45px;
      flex-wrap: nowrap;
    }


    @include md {
      font-size: 15px;

      padding: 11px 25px;

      text-align: center;
      justify-content: center;
    }

    @include sm {
      padding: 11px 45px;
      flex-wrap: nowrap;
    }

    &::before {
      content: counter(list-tabs, decimal-leading-zero);
      counter-increment: list-tabs;

      font-size: 22px;
      font-weight: 700;
      margin-right: 8px;

      @include rtl {
        margin-right: 0;
        margin-left: 8px;
      }

      @include xxxl {
        font-size: 16px;
      }

      @include xxl {
        margin-right: 4px;

        @include rtl {
          margin-right: 0;
          margin-left: 4px;
        }
      }

      @include md {
        font-size: 15px;
      }
    }
  }
}
