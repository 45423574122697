.filter-block {
  &__title {
    margin-bottom: 8px;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    @include sm {
      gap: 6px;
    }
  }

  &__item {
  }

  &__btn {
    display: inline-flex;
    position: relative;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }

    input {
      @extend %hidden;
      position: absolute;

      &:checked + span {
        background-color: $light;
        border-color: $light;
        color: $c-text;
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      border: 1px solid $light;

      min-width: 79px;
      padding: 7px 20px;

      @include sm {
        padding: 7px 12px;
      }
    }
  }
}
