.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__offline-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    right: 0;

    background-color: $black;
    color: $white;

    height: 30px;
  }

  &__header {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;

    min-width: 320px;

    .is-offline & {
      top: 30px;
    }

    .has-open-products-nav & {
      z-index: 1;
    }
  }

  &__main {
    flex-grow: 1;
  }

  &__preloader {
    @extend %overlay;
    z-index: 15;
    background-color: rgba($black, 0.5);
    transition: opacity 300ms, visibility 300ms;

    &--hidden {
      @extend %hidden;
    }
  }

  &--animated {
    .header__inner .logo,
    .header__inner .header__controls,
    .hero__title,
    .hero__text,
    .tabs {
      opacity: 0;
    }

    .hero__bg--animated img {
      transform: translate(150%, 0);
    }
  }
}
