.brands {
  background-color: #f6f6f6;
  padding-top: 24px;
  padding-bottom: 30px;

  &__title {
    margin-bottom: 24px;
  }
}

.brands-list {
  @extend %row;

  margin-right: -56px;

  @include xl {
    margin-right: -49px;
  }

  @include md {
    margin-right: -39px;
  }

  @include sm {
    margin-right: -20px;
  }

  &__item {
    width: 100px;

    margin-right: 55px;
    margin-bottom: 10px;

    @include xl {
      margin-right: 49px;
    }

    @include md {
      margin-right: 39px;
    }

    @include sm {
      margin-right: 20px;
      width: 64px;
    }

    .brand {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.brand {
  $this: &;

  display: block;
  text-align: center;

  transition: coor 300ms;

  @include hover {
    color: $accent;

    #{$this}__img {
      border-color: $accent;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid $light;
    border-radius: 50%;

    width: 100px;
    height: 100px;

    margin-bottom: 12px;

    transition: border-color 300ms;

    @include sm {
      width: 64px;
      height: 64px;

      margin-bottom: 6px;
    }

    img {
      max-width: 60%;
    }
  }

  &__title {
    font-size: 16px;
    text-transform: uppercase;

    @include xxxl {
      font-size: 12px;
    }

    @include sm {
      font-size: 10px;
    }
  }

  &--empty {
    .brand__img {
      background-color: #f6f6f6;
    }
  }
}

.brands-slider {
  $this: &;

  padding-top: 47px;
  padding-bottom: 64px;

  @include sm {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 16px;
    padding-left: 16px;
  }

  &__desk {
    @include sm {
      display: none;
    }

    #{$this}__paging {
      @extend %row;
      align-items: center;
      justify-content: center;

      margin-bottom: 36px;
    }

    #{$this}__body {
      position: relative;

      transition: height 300ms;
    }

    #{$this}__item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      @extend %hidden;

      transition: opacity 300ms, visibility 300ms;

      &[data-state='active'] {
        @extend %visible;
      }
    }

    #{$this}__paging-item {
    }

    #{$this}__paging-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 45px;
      height: 45px;

      border-radius: 25px;

      font-size: 24px;
      letter-spacing: 0.04em;

      transition: color 300ms, background-color 300ms;

      &[data-state='active'] {
        background-color: $accent;
        color: $white;
      }
    }
  }

  &__mob {
    display: none;

    @include sm {
      display: block;
    }
  }
  &__accord{
    &:nth-child(1){
      button{
        border-top: none;
      }
    }
    &:nth-last-child(1){
      button{
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  &__accord-btn {
    display: block;

    position: relative;

    text-align: inherit;
    font-size: 18px;
    letter-spacing: 0.04em;

    width: 100%;
    padding: 8px;
    border-top: 1px solid #d8d8d8;


    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;

      transform: translate(0, -50%);
      @include arr(14px, 8px, $accent, b);

      pointer-events: none;

      @include rtl {
        right: auto;
        left: 8px;
      }
    }

    &[data-state='active'] {
      color: $accent;

      &::after {
        transform: translate(0, -50%) scaleY(-1);
      }
    }
  }

  &__accord-item {
    transition: max-height 300ms;

    overflow: hidden;
    max-height: 0;

    transition: max-height 400ms;

    .brands-list {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &[data-state='open'] {
    }
  }
}
