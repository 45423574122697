.header {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    
    height: $header-height + px;

    @include xxxl {
      height: $header-height-xxxl + px;
    }

    @include lg {
      height: $header-height-lg + px;
    }

    @include sm {
      height: $header-height-sm + px;


      padding-right: 15px;
      padding-left: 15px;


      @include rtl {
        padding-right: 0;
        padding-left: 30px;
      }
    }

    @include xxxs {
      padding-right: 15px;

      @include rtl {
        padding-right: 0;
        padding-left: 15px;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__country {
    padding-right: 72px;

    @include rtl {
      padding-right: 0;
      padding-left: 72px;
    }

    @include xxxl {
      padding-right: 52px;

      @include rtl {
        padding-right: 0;
        padding-left: 52px;
      }
    }

    @include md {
      padding-right: 48px;

      @include rtl {
        padding-right: 0;
        padding-left: 48px;
      }
    }

    @include sm {
      padding-right: 36px;

      @include rtl {
        padding-right: 0;
        padding-left: 36px;
      }
    }

    @include xxxs {
      padding-right: 10px;

      @include rtl {
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
}
