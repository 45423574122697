@use 'sass:math';

.hero {
  position: relative;
  z-index: 0;

  padding-top: 28 + $header-height + px;
  padding-bottom: 332px;

  margin-bottom: -242px;

  overflow: hidden;


  @include xxxl {
    padding-top: 24 + $header-height-xxxl + px;
    padding-bottom: 225px;
    margin-bottom: -164px;
  }

  @include lg {
    padding-top: 20 + $header-height-lg + px;
    padding-bottom: 175px;
    margin-bottom: -135px;
  }

  @include md {
    padding-top: 8 + $header-height-lg + px;
    padding-bottom: 249px;
  }

  @include sm {
    padding-top: 16 + $header-height-sm + px;
    padding-bottom: 270px;
    margin-bottom: -140px;
    padding-left: 16px;
    padding-right: 16px;
  }

  & + div {
    position: relative;
    z-index: 1;
  }

  &__bg {
    @extend %coverdiv;
    z-index: 0;
    overflow: hidden;

    @include xxxlmin {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      &:nth-child(1) {
        position: absolute;
        bottom: 0;
        right: 32px;

        width: 1704px;
        height: 680px;
        margin: auto;
        object-fit: contain;
        object-position: right bottom;
        bottom: 20px;


        @include xxxl { 
          width: 1280px;
          height: 430px;
          right: calc((100% - 1280px)/2 - 20px);
        }
        @include xl{
          width: 944px;
          right: calc((100% - 944px)/2 - 20px);
        }
        @include lg {
          height: 380px;

        }

        @include md {
          width: 704px;
          right: calc((100% - 704px)/2 - 20px);
          height: 350px;
          bottom: 30px;

          @include rtl {
            right: auto;
            left: -5%;
          }
        }

        @include sm {
        height: 220px;
        right: 0;
          bottom: 70px;
          max-width: none;

          @include rtl {
            right: auto;
            left: 2%;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        position: absolute;
        z-index: 0;
        bottom: 0;
      }

      &:nth-child(2) {
        left: 50%;
        bottom: 10%;
        width: 514px;

        @include rtl {
          left: auto;
          right: 62%;
        }

        @include xxxl {
          left: 53%;
          width: 325px;
        }
        @include xxl {
          left: 50%;
          width: 300px;
        }

        @include lg {
          width: 260px;

          left: 52%;
          bottom: 12%;

          @include rtl {
            left: auto;
            right: 62%;
          }
        }

        @include md {
          width: 158px;
          left: 60%;
          bottom: 20%;

          @include rtl {
            left: auto;
            right: 62%;
          }
        }

        @include sm {
          left: 21%;
          bottom: 17%;

          @include rtl {
            left: auto;
            right: 5%;
          }
        }
      }

      &:nth-child(3) {
        left: 67%;
        bottom: -4%;
        width: 485px;

        @include rtl {
          left: auto;
          right: 50%;
        }

        @include xxxl {
          width: 305px;
        }
        @include xxl {
          left: 63%;
          width: 280px;
        }

        @include lg {
          width: 240px;

          bottom: 2%;
        }

        @include md {
          width: 148px;
          bottom: 15%;
          left: 72%;

          @include rtl {
            left: auto;
            right: 50%;
          }
        }

        @include sm {
          bottom: 11%;
          left: 45%;

          @include rtl {
            left: auto;
            right: -5%;
          }
        }
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__title {
    max-width: 891px;
    margin-bottom: 28px;
    @include text-gradient;

    @include xxxl {
      max-width: 685px;
      margin-bottom: 16px;
    }

    @include lg {
      max-width: 532px;
    }
  }

  &__text {
    max-width: 726px;

    @include xxxl {
      max-width: 545px;
    }

    @include lg {
      letter-spacing: 0.04em;
      max-width: 434px;


    }

    @include md {
      max-width: 374px;
      letter-spacing: initial;      
      br {
        display: none;
      }
    }
  }
}
