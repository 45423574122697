.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: $black;
  color: $white;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.04em;

  padding: 18px 18px 19px;

  transition: color 300ms, background-color 300ms, border-color 300ms;

  @include hover {
    background-color: #424242;
  }

  @include xxxl {
    font-size: 12px;
    padding: 13px;
  }

  &--accent {
    background-color: $accent;

    @include hover {
      background-color: $accent-hover;
    }
  }

  &--blue {
    border: 1px solid $accent-blue;
    background-color: $accent-blue;
    color: $white;
    font-size: 22px;

    padding: 20px;
    min-width: 372px;

    @include hover {
      color: $accent;
      background-color: transparent;
    }

    @include xxxl {
      font-size: 16px;
      padding: 17px 15px 16px;
      min-width: 280px;
    }
  }
}
