.footer {
  @include sm {
    font-size: 13px;
    padding-right: 16px;
    padding-left: 16px;
  }

  &__inner {
    max-width: percentage(math.div(1184, 1280));
    margin-left: auto;
    margin-right: auto;

    padding-top: 58px;
    padding-bottom: 65px;

    @include xxxl {
      padding-top: 24px;
      padding-bottom: 31px;
    }

    @include lg {
      max-width: percentage(math.div(872, 944));
    }

    @include sm {
      max-width: none;
      padding-top: 20px;
      padding-bottom: 34px;
    }

    .js-truncate-text {
      font-weight: 500;
    }

    .has-long-text {
      button {
        display: none;
      }
    }
  }
}
