html,
body {
  height: 100%;
}

html {
  font-size: 20px;
  line-height: 1.2;
  color: $c-text;

  @include reg;

  @include xxxl {
    font-size: 16px;
  }

  @include sm {
    font-size: 15px;
  }

  &.is-offline {
    padding-top: 30px;
  }
}

body {
  background-color: $light-grey;
  color: $c-text;
  min-width: 320px;
}

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit;

  &--stroke {
    fill: none;
    stroke: currentColor;
  }
}

.lazy {
  $this: &;

  &--loaded:not(#{$this}--no-fade-in) {
    animation: fade-in 1s;
  }
}

.preloader {
  display: flex;

  &__inner {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: auto;
    max-width: 300px;
  }

  &__dot {
    width: 50px;
    height: 50px;
    background-color: $accent;
    border-radius: 50%;
    animation: preloader 1000ms infinite;

    &:nth-child(1) {
    }

    &:nth-child(2) {
      animation-delay: 150ms;
    }

    &:nth-child(3) {
      animation-delay: 300ms;
    }

    &:nth-child(4) {
      animation-delay: 450ms;
    }
  }
}

.full-width{
  width: 100vw;
  margin-left: calc(0px - (100vw - 100%)/2 );
  background-color: #ffffff;

  >div{
    padding: 50px 48px 48px;
    @include sm{
      padding: 0;
    }
  }


}
.full-width+.full-width{
  &.info-block-container{
    @include sm{
      padding-top: 0;
    }
  }
  >div{
    padding: 0px 48px 48px;
    @include sm{
      padding: 0;
    }
  }
}

.container {
  width: 100%;
  max-width: 1704px + 30px;


  margin-left: auto;
  margin-right: auto;

  @include xxxl {
    max-width: 1280px + 30px;
  }

  @include xl {
    max-width: 944px + 30px;
  }

  @include md {
    max-width: 704px + 30px;
  }
  @include sm {
    padding-left: 0;
    padding-right: 0;
  }
}

.logo {
  $this: &;

  display: inline-flex;
  width: 200px;

  @include xxxl {
    width: 180px;
  }

  @include md {
    width: 150px;
  }

  @include sm {
    width: 120px;
  }


  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;

    width: 100%;

    letter-spacing: 0.04em;



    img {
   
        width: 100%;

    }
    >span{
      font-family: 'IBM Plex Sans';
      font-style: italic;
      text-transform: none;
      color: $accent-blue;
      text-align: left;
      font-size: 15px;

      @include xxxl {
        font-size: 14px;
      }
    
      @include md {
        font-size: 12px
      }
    
      @include sm {
        font-size: 10px
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 1;

    top: -1px;
    bottom: -1px;
    right: -1px;
    width: 102px;

    background-color: $accent;
    color: $white;

    clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);

    transition: background-color 300ms;

    @include rtl {
      right: auto;
      left: -1px;
      clip-path: polygon(0 0,100% 0,80% 100%,0 100%);
    }

    @include xxxl {
      width: 76px;
    }

    @include md {
      width: 70px;
    }

    @include sm {
      width: 38px;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
    }

    .icon {
      font-size: 13px;

      @include rtl {
        transform: scaleX(-1);
      }

      @include xxxl {
        font-size: 10px;
      }

      @include sm {
        font-size: 8px;
      }
    }
  }
}

.country-select {
  &__panel {
    display: flex;
    align-items: center;

    font-size: 22px;
    letter-spacing: 0.04em;

    cursor: pointer;

    @include xxxl {
      font-size: 16px;
    }
  }

  &__img {
    margin-right: 5px;
    display: flex;
    flex-shrink: 0;

    @include rtl {
      margin-right: 0;
      margin-left: 5px;
    }

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    transition: color 300ms;

    @include hover {
      color: $accent;
    }

    .icon {
      margin-left: 5px;

      @include rtl {
        margin-left: 0;
        margin-right: 5px;
        transform: scaleX(-1);
      }
    }
  }
}

.lang {
  $this: &;

  position: relative;

  &:hover {
    #{$this}__list {
      @extend %visible;
    }
  }

  ul {
    @extend %row;

    text-align: center;

    @include xxl {
      max-width: 195px;
      margin-left: auto;
      margin-right: auto;
    }

    li {
      width: percentage(math.div(1, 3));
      padding-top: 2px;
      padding-bottom: 3px;
    }

    a {
      transition: color 300ms;

      @include hover {
        color: $accent;
      }
    }
  }

  &__list-in {
    background-color: $black;
    padding: 16px 4px;
    position: relative;

    @include xxl {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &::before {
      content: '';

      position: absolute;
      bottom: 100%;
      right: 15px;

      @include arr(20px, 13px, $black, t);

      display: none;

      @include rtl {
        right: auto;
        left: 15px;
      }

      @include xxl {
        display: block;
      }
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 160px;

    padding-top: 12px;

    @extend %hidden;

    transition: opacity 300ms, visibility 300ms;

    @include xxl {
      transform: none;
      left: auto;
      right: -12px;
      padding-top: 20px;
      width: 350px;

      @include rtl {
        right: auto;
        left: -12%;
      }
    }

    @include xxxs {
      width: 250px;
      right: 0;

      @include rtl {
        right: auto;
        left: 0;
      }
    }
  }

  &__title {
    cursor: pointer;

    font-size: 22px;

    transition: color 300ms;

    @include hover {
      color: $accent;
    }

    @include xxxl {
      font-size: 16px;
    }
  }
}

.vehicle-cards {
  @extend %row;

  margin-left: -12px;
  margin-right: -12px;

  @include lg {
    margin-left: -8px;
    margin-right: -8px;
  }

  @include md {
    margin-left: -4px;
    margin-right: -4px;
  }
  @include sm {
    padding-right: 16px;
    padding-left: 16px;
  }

  > li {
    width: percentage(math.div(1, 4));
    padding-left: 12px;
    padding-right: 12px;

    @include lg {
      padding-left: 8px;
      padding-right: 8px;
    }

    @include md {
      padding-left: 4px;
      padding-right: 4px;
    }

    @include sm {
      width: 50%;
      margin-bottom: 8px;
    }

    .vehicle-card {
      height: 100%;
    }

    &:nth-child(1) {
      .vehicle-card {
        img {
          bottom: 5%;

          @include lg {
            max-width: 90%;
          }
        }
      }
    }

    &:nth-child(2) {
      .vehicle-card {
        img {
          bottom: 10%;

          @include lg {
            max-width: 90%;
          }
        }
      }
    }

    &:nth-child(3) {
      .vehicle-card {
        img {
          bottom: 8%;
        }
      }
    }

    &:nth-child(4) {
      .vehicle-card {
        img {
          bottom: 14%;

          @include lg {
            max-width: 90%;
            bottom: 12%;
          }
        }
      }
    }
  }
}

.vehicle-card {
  $this: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  position: relative;

  padding: 32px 14px 11px 14px;
  padding-bottom: 85.2%;

  background: $light-grey;
  color: $c-text;

  @include hover {
    #{$this}__title {
      transform: scale(1.1);
    }

    &::before {
      @extend %visible;
    }
  }

  &::before {
    content: '';

    @extend %coverdiv;
    @extend %hidden;

    z-index: 0;

    background: #e3e5e686;

    transition: opacity 300ms, visibility 300ms;
  }

  @include lg {
    padding: 24px 12px;
    padding-bottom: 84.5%;
  }

  @include md {
    padding: 16px 12px;
    padding-bottom: 84.5%;
  }

  img {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__title {
    position: relative;
    z-index: 2;

    font-size: 28px;
    font-weight: 500;

    transition: transform 300ms;

    @include xxxl {
      font-size: 26px;
    }

    @include md {
      font-size: 20px;
    }
  }
}

.info-list-items {
  background-color: $lighten;

  padding-top: 40px;
  padding-bottom: 40px;

  @include sm {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.info-items {
  @extend %row;

  margin-left: -4px;
  margin-right: -4px;

  &__item {
    width: percentage(math.div(1, 4));
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 8px;

    @include md {
      width: percentage(math.div(1, 3));
    }

    @include sm {
      width: percentage(math.div(1, 2));
    }

    @include xs {
      width: 100%;
    }
  }

  &__btn {
    display: block;
    padding: 6px 8px;
    background-color: $white;
    line-height: 1.6;

    transition: color 300ms;

    @include hover {
      color: $accent;
    }
  }
}

.links-block {
  &__title {
    margin-bottom: 28px;
    font-style: normal;
    letter-spacing: normal;

    @include xxxl {
      margin-bottom: 18px;
    }

    @include sm {
      font-size: 18px;
      margin-bottom: 12px;
    }
  }
}

.links {
  $this: &;

  &__item {
    margin-bottom: 16px;

    @include xxxl {
      margin-bottom: 12px;
    }

    @include sm {
      margin-bottom: 6px;
    }
  }

  &__link {
    color: $accent;

    transition: color 300ms;

    @include hover {
      color: $black;
    }
  }

  &--cols {
    @extend %row;

    margin-left: -4px;
    margin-right: -4px;

    #{$this}__item {
      width: percentage(math.div(1, 3));
      padding-left: 4px;
      padding-right: 4px;

      @include md {
        width: 50%;
      }

      @include sm {
        width: 100%;
      }
    }
  }
}

.title-block {
  @extend %row;
  

  &__img-wrapper{

      height: 120px;
      width: 194px;
      background-color: #F5F8F9;
      margin-top: 20px;

      @include sm{
        margin-top: 16px;
      }

      img {
          width: 100%;
          height: 100%;
          object-fit: contain;
    
    }
  }

  &__title {
    width: percentage(math.div(416, 1184));

    // > .title {
    //   &:not(:last-child) {
    //     margin-bottom: 8px;
    //   }
    // }



    @include sm {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &__text {
    padding-left: 40px;
    width: percentage(math.div(1184 - 416, 1184));

    @include rtl {
      padding-left: 0;
      padding-right: 40px;
    }

    @include sm {
      width: 100%;
      padding-left: 0;

      @include rtl {
        padding-right: 0;
      }
    }

    .text {
      position: relative;

      &::after {
        content: '';

        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;

        height: 52px;

        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        pointer-events: none;

        display: none;
      }

      strong{
        color: $accent-blue;
      }

      @include md {
        max-height: 6.8em;
        overflow: hidden;

        &::after {
          display: block;
        }
      }
    }

    .read-more-btn {
      font-weight: 700;
      display: none;
      color: $accent-red;

      @include md {
        display: inline-block;
      }
    }

    &[data-state='open'] {
      .read-more-btn {
        display: none;
      }

      .text {
        overflow: visible;
        max-height: none;

        &::after {
          display: none;
        }
      }
    }
  }
}

.tooltip {
  position: absolute;
  z-index: 5;
  bottom: calc(100% + 14px);
  right: -93px;

  background-color: $black;
  color: $white;

  padding: 16px;

  font-size: 16px;

  width: 412px;

  @extend %hidden;

  @include rtl {
    right: auto;
    left: -93px;
  }

  @include xxxl {
    font-size: 12px;
    width: 308px;
  }

  @include sm {
    bottom: auto;
    top: calc(100% + 13px);
    width: 270px;
    right: auto;
    left: -199px;

    @include rtl {
      left: auto;
      right: -199px;
    }
  }

  &::before {
    content: '';

    position: absolute;
    top: 100%;
    right: 95px;

    @include arr(20px, 13px, $black, b);

    @include rtl {
      right: auto;
      left: 95px;
    }

    @include sm {
      top: auto;
      bottom: 100%;
      transform: scaleY(-1);
      right: 50px;

      @include rtl {
        right: auto;
        left: 50px;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;

    @include sm {
      display: none;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      font-style: normal;

      width: 24px;
      height: 24px;
      flex-shrink: 0;

      border-radius: 50%;
      background-color: #f9a51a;
      color: $white;

      margin-left: 12px;

      @include rtl {
        margin-left: 0;
        margin-right: 12px;
      }

      @include sm {
        width: 22px;
        height: 22px;
      }

      .icon {
        font-size: 14px;
      }

      &:hover {
        .tooltip {
          @extend %visible;
        }
      }
    }

    .title--h4 {
      @include sm {
        font-size: 18px;
      }
    }

    &--mob {
      display: none;

      @include sm {
        display: flex;
      }
    }
  }
}

.read-more-btn {
  color: $accent;
  text-decoration: underline;
}

@keyframes preloader {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
