.search-block {
  @extend %row;

  @include sm{
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
  }

  &__content {
    width: percentage(math.div(664, 1184));
    padding-right: 24px;

    @include rtl {
      padding-right: 0;
      padding-left: 24px;
    }

    @include lg {
      > p {
        letter-spacing: 0.04em;
      }
    }

    @include md {
      width: percentage(math.div(332, 656));

      > p {
        letter-spacing: initial;
      }
    }

    @include sm {
      width: 100%;
      padding-right: 0;
      margin-bottom: 16px;

      @include rtl {
        padding-left: 0;
      }
    }
  }

  &__content-in {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @include xs {
      gap: 12px;
    }

    > img {
      width: 100px;

      @include lg {
        width: 64px;
      }
    }
    p{
      strong{
        color: $accent-blue;
      }
    }
  }

  &__form {
    width: percentage(math.div(1184 - 664, 1184));

    @include md {
      width: percentage(math.div((656 - 332), 656));
    }

    @include sm {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 4px;
    @include text-gradient;
    width: fit-content;

    @include lg {
      margin-bottom: 8px;
    }
    @include sm {
      margin-top: 30px;
    }
  }
}

.search {
  $this: &;

  position: relative;

  input {
    width: 100%;
    height: 56px;
    border: 1px solid  #93B1C166;
    padding: 19px 20px;
    padding-right: 50px;

    @include rtl {
      padding-right: 19px;
      padding-left: 50px;
    }

    @include lg {
      height: 52px;
      padding: 17px 12px;
      padding-right: 46px;

      @include rtl {
        padding-right: 17px;
        padding-left: 46px;
      }
    }

    &::placeholder {
      color:  #00000066;
      ;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 1;
    top: 50%;
    right: 4px;
    transform: translate(0, -50%);

    width: 48px;
    height: 48px;

    color: $white;
    background-color: $accent;

    transition: background-color 300ms;

    @include rtl {
      right: auto;
      left: 4px;
    }

    @include hover {
      background-color: $black;
    }

    @include lg {
      width: 44px;
      height: 44px;
    }

    .icon {
      font-size: 16px;
    }
  }

  &__clear {
    display: none;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    right: 56px;
    transform: translate(0, -50%);

    width: 30px;
    height: 30px;

    @include rtl {
      right: auto;
      left: 56px;
    }

    .icon {
      font-size: 10px;
    }
  }

  &__wrap {
    position: relative;

    ul {
      padding: 16px 20px;
      max-height: 262px;
      overflow: auto;

      @include scrollbar;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    a,
    button {
      text-align: inherit;
      transition: color 300ms;

      @include hover {
        color: $accent;
      }
    }

    &[data-state='has-text'],
    &[data-state='has-error'] {
      #{$this}__clear {
        display: flex;
      }
    }

    &[data-state='has-error'] {
      #{$this}__clear {
        color: $danger;
      }
    }
  }

  &__list-wrap {
    position: absolute;
    z-index: 5;
    top: calc(100% + 4px);
    left: 0;
    right: 0;

    background-color: $white;
    border: 1px solid $black;

    padding-right: 3px;
    padding-top: 4px;
    padding-bottom: 4px;

    transition: opacity 300ms, visibility 300ms;

    @extend %hidden;

    &[data-state='open'] {
      @extend %visible;
    }
  }

  &__empty-message {
    span {
      color: $danger;

      @include sm {
        display: block;
      }
    }

    &--hidden {
      display: none;
    }
  }
}
