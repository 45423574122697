
@mixin reg {
  font-family: 'IBM Plex Sans', sans-serif;
}

@include font-woff2('IBM Plex Sans', 'IBMPlexSans-Regular');
@include font-woff2('IBM Plex Sans', 'IBMPlexSans-Medium', 500);
@include font-woff2('IBM Plex Sans', 'IBMPlexSans-SemiBold', 600);
@include font-woff2('IBM Plex Sans', 'IBMPlexSans-Bold', 700);
@include font-woff2('IBM Plex Sans', 'IBMPlexSans-BoldItalic', 700, italic);

@include font-woff('IBM Plex Sans', 'IBMPlexSans-Regular');
@include font-woff('IBM Plex Sans', 'IBMPlexSans-Medium', 500);
@include font-woff('IBM Plex Sans', 'IBMPlexSans-SemiBold', 600);
@include font-woff('IBM Plex Sans', 'IBMPlexSans-Bold', 700);
@include font-woff('IBM Plex Sans', 'IBMPlexSans-BoldItalic', 700, italic);